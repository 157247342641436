<!--
 * @Description:来去电工作台table 
 * @Author: ChenXueLin
 * @Date: 2021-10-19 16:21:55
 * @LastEditTime: 2021-10-19 16:50:40
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <!-- 搜索头部 start -->
    <table-title :showSearch="false">
      <template v-slot:searchForm>
        <!-- 订单页面搜索条件 -->
        <el-form
          class="search-list"
          ref="searchForm"
          :model="searchForm"
          v-show="pageIndex == 1"
        >
          <el-form-item class="search-item--1" prop="orderNo">
            <el-input
              v-model="searchForm.orderNo"
              placeholder="合同编号"
              title="合同编号"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--1" prop="orderNo">
            <el-input
              v-model="searchForm.orderNo"
              placeholder="订单编号"
              title="订单编号"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--buttons">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button class="reset" @click="handleReset">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- 名下终端搜索条件 -->
        <el-form
          class="search-list"
          ref="searchForm"
          :model="searchForm"
          v-show="pageIndex == 2"
        >
          <el-form-item class="search-item--1" prop="orderNo">
            <el-input
              v-model="searchForm.orderNo"
              placeholder="场景名称"
              title="场景名称"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--1" prop="orderNo">
            <el-input
              v-model="searchForm.orderNo"
              placeholder="设备编号"
              title="设备编号"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--buttons">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button class="reset" @click="handleReset">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- 名下设备搜索条件 -->
        <el-form
          class="search-list"
          ref="searchForm"
          :model="searchForm"
          v-show="pageIndex == 3"
        >
          <el-form-item class="search-item--1" prop="orderNo">
            <el-input
              v-model="searchForm.orderNo"
              placeholder="商品名称"
              title="商品名称"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--1" prop="orderNo">
            <el-input
              v-model="searchForm.orderNo"
              placeholder="设备编号"
              title="设备编号"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--buttons">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button class="reset" @click="handleReset">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- 工单搜索条件 -->
        <el-form
          class="search-list"
          ref="searchForm"
          :model="searchForm"
          v-show="pageIndex == 4"
        >
          <el-form-item class="search-item--1" prop="orderNo">
            <el-input
              v-model="searchForm.orderNo"
              placeholder="工单号"
              title="工单号"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--buttons">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button class="reset" @click="handleReset">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- 公司账号搜索条件 -->
        <el-form
          class="search-list"
          ref="searchForm"
          :model="searchForm"
          v-show="pageIndex == 5"
        >
          <el-form-item class="search-item--1" prop="orderNo">
            <el-input
              v-model="searchForm.orderNo"
              placeholder="用户名"
              title="用户名"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--buttons">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button class="reset" @click="handleReset">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- 立即监控搜索条件 -->
        <el-form
          class="search-list"
          ref="searchForm"
          :model="searchForm"
          v-show="pageIndex == 6"
        >
          <el-form-item class="search-item--1" prop="orderNo">
            <el-input
              v-model="searchForm.orderNo"
              placeholder="终端名称"
              title="终端名称"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--1" prop="orderNo">
            <el-input
              v-model="searchForm.orderNo"
              placeholder="中心识别码"
              title="中心识别码"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--buttons">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button class="reset" @click="handleReset">重置</el-button>
          </el-form-item>
        </el-form>
      </template>
    </table-title>
    <!-- 搜索头部 end -->
    <!-- 订单table -->
    <el-table
      border
      height="300px"
      :data="tableData"
      highlight-current-row
      class="elTable"
    >
      <!-- 序号 start -->
      <el-table-column
        label="序号"
        width="50"
        fixed="left"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.$index + 1 }}
          </span>
        </template>
      </el-table-column>
      <!-- 序号 end -->
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in columnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import base from "@/mixins/base";
export default {
  name: "",
  components: {},
  props: {
    pageIndex: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      searchForm: {},
      tableData: [],
      orderColumn: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "合同编号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "订单编号",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "下单时间",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "要求完成时间",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "订单状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "首次调度时间",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "首台安装时间",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "申请装车",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "已装车",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "已匹配",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //订单页面表头
      terminalColumn: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "场景名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "场景类型",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "终端状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "已安装商品",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "商品分类",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "设备编号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "中心识别码",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "SIM卡号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //名下终端页面表头
      deviceColumn: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "商品名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "商品分类",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "终端状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "设备编号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "关联订单",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //名下设备页面表头
      accountColumn: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "工单号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "工单类型",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "工单二级类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "工单状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "创建人",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "创建时间",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //工单页面表头
      taskOrderColumn: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "账号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "密码",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "用户名",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "用户等级",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "启用状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "添加时间",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //公司账号页面表头
      controlColumn: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "终端名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "中心识别码",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "GPS时间",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "速度",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "里程",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "经度",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "纬度",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "方向",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "地点名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "道路名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //立即监控页面表头
      bookColumn: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "联系人名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "职责",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "电话",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "电话2",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ] //通讯录页面表头
    };
  },
  mixins: [base],
  computed: {
    columnData() {
      if (this.pageIndex == 1) {
        return this.orderColumn;
      } else if (this.pageIndex == 2) {
        return this.terminalColumn;
      } else if (this.pageIndex == 3) {
        return this.deviceColumn;
      } else if (this.pageIndex == 4) {
        return this.accountColumn;
      } else if (this.pageIndex == 5) {
        return this.taskOrderColumn;
      } else if (this.pageIndex == 6) {
        return this.controlColumn;
      } else {
        return this.bookColumn;
      }
    }
  },
  watch: {},
  methods: {},
  created() {}
};
</script>
<style lang="scss" scoped></style>
